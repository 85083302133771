<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Details"
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <time-sheet-details-card
                :loading="helpers.dataIsLoading"
                :timeSheet="timeSheet"
              ></time-sheet-details-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-skeleton-loader
                v-if="helpers.dataIsLoading"
                elevation="2"
                type="card-heading, list-item, divider, article, divider, actions"
              ></v-skeleton-loader>
              <v-row v-else class="match-height">
                <v-col cols="6">
                  <statistics-single-card
                    title="Geleistete Zeit"
                    color="blue-grey"
                    icon="mdi-timer-sand-complete"
                    :value="
                      getTimeSheetDurationText(
                        timeSheet.workedDuration.inMinutes
                      )
                    "
                  ></statistics-single-card>
                </v-col>
                <v-col cols="6">
                  <statistics-single-card
                    title="Abrechenbare Zeit"
                    color="green"
                    icon="mdi-cash-check"
                    :value="
                      (timeSheet.status === 'toBeReviewed' ||
                        timeSheet.status === 'approved' ||
                        timeSheet.status === 'completed') &&
                      timeSheet.calculatedSalary.baseVariables.MINUTES
                        ? getTimeSheetDurationText(
                            timeSheet.calculatedSalary.baseVariables.MINUTES
                          )
                        : 'Zu prüfen'
                    "
                  ></statistics-single-card>
                </v-col>
                <v-col
                  v-if="timeSheet.calculatedSalary.formula !== null"
                  cols="6"
                >
                  <statistics-single-card
                    title="Berechnungsgrundlage"
                    color="blue"
                    icon="mdi-calculator"
                  >
                    <template v-slot:value>
                      <pre class="font-weight-medium"
                        >{{ timeSheet.calculatedSalary.formula }} </pre
                      >
                    </template>
                  </statistics-single-card>
                </v-col>
                <v-col
                  v-if="timeSheet.calculatedSalary.amount !== null"
                  cols="6"
                >
                  <statistics-single-card
                    title="Aufwandsentschädigung"
                    color="green"
                    icon="mdi-cash-check"
                  >
                    <template v-slot:value>
                      <pre class="font-weight-medium"
                        >{{ timeSheet.calculatedSalary.amount }} {{
                          timeSheet.calculatedSalary.currency
                        }}</pre
                      >
                    </template>
                  </statistics-single-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-history" title="Logs"> </Subheader>
              <div class="">
                <v-timeline dense align-top>
                  <v-timeline-item
                    fill-dot
                    color="white"
                    icon-color="grey"
                    icon="mdi-pencil"
                    small
                  >
                    <v-card>
                      <v-card-text>
                        <v-textarea
                          v-model="helpers.commentForm.value"
                          dense
                          label="Kommentar hinzufügen"
                          hide-details="auto"
                          outlined
                          no-resize
                          auto-grow
                          rows="3"
                        >
                        </v-textarea>
                        <v-alert
                          v-if="helpers.commentForm.errorMessage"
                          dense
                          type="error"
                          text
                          class="mt-4 mb-0"
                        >
                          {{ helpers.commentForm.errorMessage }}</v-alert
                        >
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <!-- <v-checkbox
                          dense
                          disabled
                          label="Benachrichtigung per E-Mail senden"
                          persistent-hint
                          hint="Eine Benachrichtigung über diesen Eintrag wird per E-Mail an ausgewählte Personen gesendet."
                        ></v-checkbox> -->

                        <v-spacer></v-spacer
                        ><v-btn
                          class="mx-0"
                          color="success"
                          depressed
                          :loading="helpers.commentForm.isLoading"
                          :disabled="!helpers.commentForm.value"
                          @click="addComment"
                        >
                          Hinzufügen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-timeline-item>

                  <v-slide-x-transition group>
                    <div v-for="entry in logsAndComments" :key="entry.id">
                      <v-timeline-item
                        color="white"
                        fill-dot
                        small
                        :icon-color="logEntryActionColor(entry.type)"
                        :icon="logEntryActionIcon(entry.type)"
                      >
                        <v-card flat class="elevation-2">
                          <v-card-text>
                            <v-row justify="space-between">
                              <v-col cols="7">
                                <strong>{{ entry.user.displayName }}</strong>
                                {{ logEntryActionText(entry.type) }}
                                <span v-if="entry.type === 'comment'">
                                  <br />{{ entry.text }}
                                </span>
                                <span v-if="entry.reason">
                                  <br />Grund: {{ entry.reason }}
                                </span></v-col
                              >
                              <v-col
                                class="text-right"
                                cols="5"
                                v-if="entry.timestamp"
                                >{{
                                  timestampConverter(entry.timestamp)
                                }}</v-col
                              >
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </div>
                  </v-slide-x-transition>
                  <v-divider></v-divider>
                </v-timeline>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <custom-dialog
      v-if="helpers.editTimeSheetDialog.model"
      @close="toggleEditDialog()"
      title="Stundenmeldung bearbeiten"
      text="Änderungen müssen vor der Genehmigung durchgeführt werden."
      width="l"
      :errorMessage="helpers.editTimeSheetDialog.errorMessage"
      :loading="helpers.editTimeSheetDialog.isLoading"
      :btnAction="updateTimeSheet"
      btnText="Aktualisieren"
      fullscreenOnMobile
    >
      <template v-if="comments.length" v-slot:top>
        <v-alert dense text type="error"
          >Kommentare zu dieser Stundenmeldung beachten</v-alert
        >
      </template>
      <template v-slot:content>
        <v-form
          v-model="helpers.editTimeSheetDialog.formIsValid"
          ref="editDialogForm"
          @submit.prevent="updateTimeSheet()"
        >
          <time-sheet-editor
            v-model="helpers.editTimeSheetDialog.timeSheetData"
            mode="edit"
          ></time-sheet-editor>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.requestValidationDialog.model"
      @close="toggleRequestValidationDialog()"
      title="Prüfung anfordern"
      text="Die Prüfung der Stundenmeldung wird manuell angefordert. Dieser Vorgang kann einige Minuten dauern."
      width="s"
      :errorMessage="helpers.requestValidationDialog.errorMessage"
      :loading="helpers.requestValidationDialog.isLoading"
      :btnAction="requestTimeSheetValidation"
      fullscreenOnMobile
    >
    </custom-dialog>
    <custom-dialog
      v-if="helpers.approveTimeSheetDialog.model"
      @close="toggleApproveTimeSheetDialog()"
      title="Stundenmeldung genehmigen"
      text="Änderungen müssen vor der Genehmigung durchgeführt werden. Die Genehmigung wird dann entsprechend gekennzeichnet (Genehmigt mit Änderungen)."
      width="s"
      :errorMessage="helpers.approveTimeSheetDialog.errorMessage"
      :loading="helpers.approveTimeSheetDialog.isLoading"
      :btnAction="approveTimeSheet"
      btnText="Genehmigen"
      fullscreenOnMobile
    >
      <template v-if="comments.length" v-slot:top>
        <v-alert dense text type="error"
          >Kommentare zu dieser Stundenmeldung beachten</v-alert
        >
      </template>
      <template v-slot:content>
        <v-form
          v-model="helpers.approveTimeSheetDialog.formIsValid"
          ref="approveDialogForm"
          @submit.prevent="approveTimeSheet()"
          ><v-textarea
            v-model="helpers.approveTimeSheetDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            persistent-hint
            hint="Optional"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.moveBackToReviewDialog.model"
      @close="toggleMoveBackToReviewDialog()"
      title="Stundenmeldung erneut zur Überprüfung übergeben"
      width="s"
      :errorMessage="helpers.moveBackToReviewDialog.errorMessage"
      :loading="helpers.moveBackToReviewDialog.isLoading"
      :btnAction="moveBackToReview"
      fullscreenOnMobile
    >
      <template v-if="comments.length" v-slot:top>
        <v-alert dense text type="error"
          >Kommentare zu dieser Stundenmeldung beachten</v-alert
        >
      </template>
      <template v-slot:content>
        <v-form
          v-model="helpers.moveBackToReviewDialog.formIsValid"
          ref="moveBackToReviewDialog"
          @submit.prevent="moveBackToReview()"
          ><v-textarea
            v-model="helpers.moveBackToReviewDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            persistent-hint
            hint="Optional"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.rejectTimeSheetDialog.model"
      @close="toggleRejectTimeSheetDialog()"
      title="Stundenmeldung ablehnen"
      width="s"
      :errorMessage="helpers.rejectTimeSheetDialog.errorMessage"
      :loading="helpers.rejectTimeSheetDialog.isLoading"
      :btnAction="rejectTimeSheet"
      :btnDisabled="!helpers.rejectTimeSheetDialog.formIsValid"
      btnText="Ablehnen"
      fullscreenOnMobile
    >
      <template v-if="comments.length" v-slot:top>
        <v-alert dense text type="error"
          >Kommentare zu dieser Stundenmeldung beachten</v-alert
        >
      </template>
      <template v-slot:content>
        <v-form
          v-model="helpers.rejectTimeSheetDialog.formIsValid"
          ref="rejectDialogForm"
          @submit.prevent="rejectTimeSheet()"
          ><v-textarea
            v-model="helpers.rejectTimeSheetDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            required
            :rules="[rules.required]"
            hide-details="auto"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.archiveTimeSheetDialog.model"
      @close="toggleArchiveTimeSheetDialog()"
      title="Stundenmeldung archivieren"
      width="s"
      :errorMessage="helpers.archiveTimeSheetDialog.errorMessage"
      :loading="helpers.archiveTimeSheetDialog.isLoading"
      :btnAction="archiveTimeSheet"
      :btnDisabled="!helpers.archiveTimeSheetDialog.formIsValid"
      btnText="Archivieren"
      fullscreenOnMobile
    >
      <template v-if="comments.length" v-slot:top>
        <v-alert dense text type="error"
          >Kommentare zu dieser Stundenmeldung beachten</v-alert
        >
      </template>
      <template v-slot:content>
        <v-form
          v-model="helpers.archiveTimeSheetDialog.formIsValid"
          ref="archiveDialogForm"
          @submit.prevent="archiveTimeSheet()"
          ><v-textarea
            v-model="helpers.archiveTimeSheetDialog.reasonValue"
            outlined
            label="Begründung/Vermerk"
            auto-grow
            required
            :rules="[rules.required]"
            hide-details="auto"
            class="mt-2 pb-0 m0-0"
          ></v-textarea>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.updatePayrollTypeDialog.model"
      @close="toggleUpdatePayrollTypeDialog()"
      title="Abrechnungsart aktualisieren"
      text="Änderungen müssen vor der Genehmigung durchgeführt werden. Die Genehmigung wird dann entsprechend gekennzeichnet (Genehmigt mit Änderungen)."
      width="s"
      :errorMessage="helpers.updatePayrollTypeDialog.errorMessage"
      :loading="helpers.updatePayrollTypeDialog.isLoading"
      :btnAction="updatePayrollType"
      :btnDisabled="!helpers.updatePayrollTypeDialog.newPayrollTypeId"
      fullscreenOnMobile
    >
      <template v-if="comments.length" v-slot:top>
        <v-alert dense text type="error"
          >Kommentare zu dieser Stundenmeldung beachten</v-alert
        >
      </template>
      <template v-slot:content>
        <v-form
          v-model="helpers.updatePayrollTypeDialog.formIsValid"
          ref="updatePayrollTypeDialogForm"
          @submit.prevent="updatePayrollType()"
        >
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="helpers.updatePayrollTypeDialog.newPayrollTypeId"
                :items="payrollTypesList"
                outlined
                label="Abrechnungsart"
                item-value="meta.id"
                item-text="title"
                hide-details="auto"
                class="mt-2 pb-0 m0-0"
              >
              </v-select>
            </v-col>
            <v-col
              v-for="(requiredInputField, index) in requiredInputFields"
              :key="requiredInputField.id"
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="
                  helpers.updatePayrollTypeDialog.customVariables[index].value
                "
                :type="requiredInputField.options.type"
                :label="requiredInputField.options.label"
                outlined
                hide-details="auto"
                :hint="requiredInputField.options.hint"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </custom-dialog>
    <custom-dialog
      v-if="helpers.recalculateSalaryDialog.model"
      @close="toggleRecalculateSalaryDialog()"
      title="Aufwand neu berechnen"
      text="Die Berechnung des Aufwands mittels der festgelegten Abrechnungsart wird neu durchgeführt. Dieser Vorgang kann einige Minuten dauern."
      width="s"
      :errorMessage="helpers.recalculateSalaryDialog.errorMessage"
      :loading="helpers.recalculateSalaryDialog.isLoading"
      :btnAction="recalculateSalary"
      btnText="Aufwand neu berechnen"
      fullscreenOnMobile
    >
    </custom-dialog>
    <v-snackbar
      v-model="helpers.snackbar.isVisible"
      class="pb-4"
      bottom
      app
      timeout="3000"
      :color="helpers.snackbar.color"
      ><v-icon class="mr-1">mdi-check-circle</v-icon>
      {{ helpers.snackbar.message }}
    </v-snackbar>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db, auth, FieldValue, Timestamp, functions } from "@/firebase";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import TimeSheetEditor from "@/components/accounting/time-sheets/TimeSheetEditor.vue";
import TimeSheetDetailsCard from "@/components/accounting/time-sheets/TimeSheetDetailsCard.vue";
import StatisticsSingleCard from "@/components/statistics/StatisticsSingleCard.vue";
import { ACCOUNTING_TIME_SHEETS_review } from "@/data/permission-types.js";
import {
  ACCOUNTING,
  AVAILABILITY,
  MEMBER_MANAGEMENT,
} from "@/store/modules.js";
import {
  GET_PAYROLL_TYPES,
  GET_UNITS,
  GET_POSITIONS,
  GET_COURSES,
} from "@/store/action-types.js";
export default {
  name: "accounting-time-sheets-details",
  components: {
    NavigationBar,
    Subheader,
    TimeSheetEditor,
    TimeSheetDetailsCard,
    SupportTools,
    StatisticsSingleCard,
    CustomDialog,
  },
  props: {
    organizationId: {
      type: String,
      required: false,
    },
    itemId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      helpers: {
        dataIsLoading: true,
        optionsDataNotPreloaded: false,
        requestValidationDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
        },
        editTimeSheetDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          timeSheetData: null,
        },
        approveTimeSheetDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        rejectTimeSheetDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        moveBackToReviewDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        updatePayrollTypeDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          newPayrollTypeId: "",
          customVariables: [],
        },
        recalculateSalaryDialog: {
          model: false,
          errorMessage: "",
          reasonValue: "",
          isLoading: false,
        },
        archiveTimeSheetDialog: {
          model: false,
          errorMessage: "",
          isLoading: false,
          reasonValue: "",
        },
        snackbar: {
          message: "",
          isVisible: false,
        },
        commentForm: {
          isLoading: false,
          value: "",
          errorMessage: "",
        },
      },
      timeSheet: null,
      logs: [],
      comments: [],
    };
  },
  computed: {
    actions() {
      const timeSheetStatus = this.timeSheet ? this.timeSheet.status : null;
      const actions = [
        {
          title: "Aktionen",
          icon: "mdi-dots-horizontal-circle",
          actionStyle: "textBtnMultiple",
          list: [
            {
              title: "Archivieren",
              permission: `${ACCOUNTING_TIME_SHEETS_review}`,
              // customPermission: isEventOrganizerWithPermission,
              icon: "mdi-archive-cancel",
              disabled: timeSheetStatus !== "toBeReviewed", // should only be true, if timeSheetStatus === 'toBeReviewed'
              function: this.toggleArchiveTimeSheetDialog,
            },
            {
              title: "Abrechnungsart aktualisieren",
              permission: `${ACCOUNTING_TIME_SHEETS_review}`,
              // customPermission: isEventOrganizerWithPermission,
              icon: "mdi-account-credit-card",
              disabled:
                timeSheetStatus !== "inProgress" &&
                timeSheetStatus !== "toBeReviewed" &&
                timeSheetStatus !== "missingPayrollType",
              function: this.toggleUpdatePayrollTypeDialog,
            },
            {
              title: "Aufwand erneut berechnen",
              permission: `${ACCOUNTING_TIME_SHEETS_review}`,
              // customPermission: isEventOrganizerWithPermission,
              icon: "mdi-restore",
              disabled: true,
              // disabled:
              // timeSheetStatus !== "toBeReviewed" ||
              // timeSheetStatus === "completed" ||
              // timeSheetStatus === "archived",
              function: this.toggleRecalculateSalaryDialog,
            },
            {
              title: "Prüfung anfordern",
              permission: `${ACCOUNTING_TIME_SHEETS_review}`,
              // customPermission: isEventOrganizerWithPermission,
              icon: "mdi-check-decagram",
              disabled:
                (timeSheetStatus !== "toBeProcessed" &&
                  timeSheetStatus !== "toBeReviewed") ||
                timeSheetStatus === "completed" ||
                timeSheetStatus === "archived",
              function: this.toggleRequestValidationDialog,
            },
            { divider: true },
            {
              title: "Qualifikationsmeldung generieren",
              // permission: `${ACCOUNTING_TIME_SHEETS_review}`,
              // customPermission: isEventOrganizerWithPermission,
              icon: "mdi-school",
              disabled: true,
              // function: this.toggleRecalculateSalaryDialog,
            },
            {
              title: "Stundenmeldung exportieren ",
              // permission: `${ACCOUNTING_TIME_SHEETS_review}`,
              icon: "mdi-file-export",
              disabled: true,
              // function: this.createFromCSV,
            },
          ],
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ];
      const approveAction = {
        title: "Genehmigen",
        permission: `${ACCOUNTING_TIME_SHEETS_review}`,
        icon: "mdi-check-circle-outline",
        color: "success",
        actionStyle: "textBtnSubmit",
        function: this.toggleApproveTimeSheetDialog,
      };
      const rejectAction = {
        title: "Ablehnen",
        permission: `${ACCOUNTING_TIME_SHEETS_review}`,
        icon: "mdi-close-circle-outline",
        actionStyle: "textBtnSubmit",
        color: "error",
        function: this.toggleRejectTimeSheetDialog,
      };
      const editAction = {
        title: "Bearbeiten",
        permission: `${ACCOUNTING_TIME_SHEETS_review}`,
        icon: "mdi-pencil",
        actionStyle: "textBtn",
        function: this.toggleEditDialog,
      };
      const allowReviewAction = {
        title: "Erneute Prüfung",
        permission: `${ACCOUNTING_TIME_SHEETS_review}`,
        icon: "mdi-check-all",
        actionStyle: "textBtn",
        color: "purple",
        function: this.toggleMoveBackToReviewDialog,
      };

      if (timeSheetStatus === "inProgress") {
        actions.unshift(editAction);
      } else if (timeSheetStatus === "toBeReviewed") {
        actions.unshift(editAction);
        actions.unshift(rejectAction);
        actions.unshift(approveAction);
      } else if (
        timeSheetStatus === "approved" ||
        timeSheetStatus === "rejected" ||
        timeSheetStatus === "archived"
      ) {
        actions.unshift(allowReviewAction);
      }
      return actions;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "timeSheet", data: this.timeSheet },
        { title: "logsAndComments", data: this.logsAndComments },
        { title: "payrollTypes", data: this.payrollTypes },
      ];
    },
    logsAndComments() {
      return this.logs
        .concat(this.comments)
        .sort((a, b) => b.timestamp - a.timestamp);
    },
    payrollTypes() {
      return this.$store.state.accounting.payrollTypes;
    },
    requiredInputFields() {
      const payrollTypes = this.payrollTypes;
      const selectedPayrollTypeId =
        this.helpers.updatePayrollTypeDialog.newPayrollTypeId;

      const selectedPayrollType = payrollTypes.find(
        (payrollType) => payrollType.meta.id === selectedPayrollTypeId
      );

      if (selectedPayrollType) {
        return selectedPayrollType.customVariables;
      }
      return [];
    },
    payrollTypesList() {
      if (!this.payrollTypes || !this.payrollTypes.length) return [];

      // Group all types together by their "group.id"
      const groupedItems = this.payrollTypes.reduce((acc, item) => {
        if (!acc[item.group.id]) acc[item.group.id] = [];
        acc[item.group.id].push(item);
        return acc;
      }, {});

      // Sort each group by their "sortKey"
      const sortedGroups = Object.values(groupedItems).map((group) =>
        group.sort((a, b) => a.sortKey - b.sortKey)
      );

      // Insert a header with the group.title in front of every group and a divider after every group
      return sortedGroups.flatMap((group) => [
        { header: group[0].group.title },
        ...group,
        { divider: true },
      ]);
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
    "helpers.updatePayrollTypeDialog.newPayrollTypeId": function () {
      this.initializeCustomVariables();
    },
  },
  methods: {
    initialize() {
      this.getTimeSheet();
      this.getLogs();
      this.getComments();
      this.helpers.dataIsLoading = false;
    },
    async getPayrollTypes() {
      try {
        await this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_TYPES}`, {
          organizationId: this.$route.params.organizationId,
        });
      } catch (error) {
        console.error("Error getting payroll types:", error);
      }
    },
    getTimeSheet() {
      const organizationId =
        this.organizationId || this.$route.params.organizationId;
      const docId = this.itemId || this.$route.params.itemId;
      return db
        .collection("organizations")
        .doc(organizationId)
        .collection("timeSheets")
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.timeSheet = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getLogs() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("timeSheets")
        .doc(this.$route.params.itemId)
        .collection("logs")
        .get()
        .then((querySnapshot) => {
          const logs = [];
          querySnapshot.forEach((doc) => {
            logs.push(doc.data());
          });
          this.logs = logs;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    getComments() {
      return db
        .collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("timeSheets")
        .doc(this.$route.params.itemId)
        .collection("comments")
        .get()
        .then((querySnapshot) => {
          const comments = [];
          querySnapshot.forEach((doc) => {
            comments.push(doc.data());
          });
          this.comments = comments;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    addComment() {
      if (this.helpers.commentForm.value != "") {
        this.helpers.commentForm.isLoading = true;
        const commentRef = db
          .collection("organizations")
          .doc(this.$route.params.organizationId)
          .collection("timeSheets")
          .doc(this.$route.params.itemId)
          .collection("comments")
          .doc();

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        return commentRef
          .set({
            user: {
              displayName: auth.currentUser.displayName,
              uid: auth.currentUser.uid,
            },
            type: "comment",
            text: this.helpers.commentForm.value,
            mentions: [],
            timestamp: FieldValue.serverTimestamp(),
            id: commentRef.id,
          })
          .then(async () => {
            this.getComments();
            await delay(800);
            this.helpers.commentForm.value = "";
            this.helpers.commentForm.isLoading = false;
          })
          .catch(async (error) => {
            console.error("Error adding document: ", error);
            await delay(800);
            this.helpers.commentForm.errorMessage = error.message;
            this.helpers.commentForm.isLoading = false;
          });
      } else {
        this.helpers.commentForm.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben ausgefüllt.";
      }
    },
    initializeCustomVariables() {
      const customVariables =
        this.helpers.updatePayrollTypeDialog.customVariables || [];
      const updatedCustomVariables = [];

      this.requiredInputFields.forEach((field) => {
        const existingVariable = customVariables.find(
          (variable) => variable.id === field.id
        );
        if (existingVariable) {
          // Use the existing variable if it exists
          updatedCustomVariables.push(existingVariable);
        } else {
          // Add a new variable if it doesn't exist
          updatedCustomVariables.push({
            id: field.id,
            value: field.options.defaultValue || null,
            options: {
              defaultValue: field.options.defaultValue || 0,
              isRequired: field.options.isRequired || true,
              label: field.options.label || "",
              pseudoId: field.options.pseudoId || "",
              type: field.options.type || "number",
              hint: field.options.hint || "",
              rules: field.options.rules || [],
            },
          });
        }
      });

      // Ensure the custom variables have the same structure and order as requiredInputFields
      this.helpers.updatePayrollTypeDialog.customVariables =
        updatedCustomVariables;
    },
    logEntryActionText(type) {
      switch (type) {
        case "created":
          return "hat die Stundenmeldung erstellt.";
        case "updated":
          return "hat die Stundenmeldung aktualisiert.";
        case "approved":
          return "hat die Stundenmeldung genehmigt.";
        case "payrollTypeUpdated":
          return "hat die Abrechnungsart aktualisiert.";
        case "toBeProcessed":
          return "hat die Stundenmeldung zur Verarbeitung übergeben.";
        case "missingPayrollType":
          return "hat die Stundenmeldung als fehlerhaft gekennzeichnet (Keine Abrechnungsart).";
        case "toBeReviewed":
          return "hat die Stundenmeldung zur Prüfung übergeben.";
        case "rejected":
          return "hat die Stundenmeldung abgelehnt.";
        case "comment":
          return "hat einen Kommentar hinzugefügt:";
        case "reassigned":
          return "hat die Stundenmeldung neu zugewiesen.";
        case "addedToPayslip":
          return "hat die Stundenmeldung einem Abrechnungszettel hinzugefügt.";
        case "waitingOnPayment":
          return "hat die Auszahlung angewiesen.";
        case "completed":
        case "paid":
          return "hat die Auszahlung erfasst und die Stundenmeldung festgeschrieben.";
        case "archived":
          return "hat die Stundenmeldung archiviert.";
        default:
          break;
      }
    },
    logEntryActionColor(type) {
      switch (type) {
        case "created":
        case "addedToPayslip":
        case "updated":
          return "primary";
        case "missingPayrollType":
          return "orange";
        case "approved":
        case "completed":
        case "paid":
          return "green";
        case "waitingOnPayment":
        case "toBeReviewed":
          return "purple";
        case "rejected":
          return "red";
        case "reassigned":
          return "primary";
        case "payrollTypeUpdated":
        case "comment":
          return "grey";
        case "archived":
          return "blue-grey";
        default:
          break;
      }
    },
    logEntryActionIcon(type) {
      switch (type) {
        case "created":
          return "mdi-plus";
        case "missingPayrollType":
          return "mdi-alert";
        case "approved":
          return "mdi-check";
        case "toBeReviewed":
          return "mdi-check-all";
        case "rejected":
          return "mdi-close";
        case "reassigned":
          return "mdi-account-switch";
        case "payrollTypeUpdated":
        case "updated":
          return "mdi-pencil";
        case "comment":
          return "mdi-comment-text";
        case "addedToPayslip":
          return "mdi-invoice-plus";
        case "waitingOnPayment":
          return "mdi-cash-clock";
        case "completed":
        case "paid":
          return "mdi-cash-check";
        case "archived":
          return "mdi-archive-cancel";
        default:
          break;
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    getAttendanceStatusText(statusId) {
      switch (statusId) {
        case "1":
          return "Anwesend";
        case "2":
          return "Entschuldigt";
        case "3":
          return "Unentschuldigt";
        default:
          return "Fehler";
      }
    },
    getTimeSheetDurationText(durationInMinutes) {
      const hours = Math.floor(durationInMinutes / 60);
      const minutes = durationInMinutes % 60;

      // Return the duration in a readable format
      return `${hours}h ${minutes}min`;
    },
    toggleRequestValidationDialog() {
      this.helpers.requestValidationDialog.model =
        !this.helpers.requestValidationDialog.model;
      this.helpers.requestValidationDialog.errorMessage = "";
    },
    toggleEditDialog() {
      if (!this.helpers.editTimeSheetDialog.model) {
        if (
          this.helpers.optionsDataNotPreloaded &&
          this.timeSheet.type === "dutyTime"
        ) {
          this.$store.dispatch(`${AVAILABILITY}${GET_POSITIONS}`, {
            organizationId: this.$route.params.organizationId,
          });
          this.$store.dispatch(`${AVAILABILITY}${GET_UNITS}`, {
            organizationId: this.$route.params.organizationId,
          });
        } else if (
          this.helpers.optionsDataNotPreloaded &&
          this.timeSheet.type === "courseTime"
        ) {
          this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_COURSES}`, {
            organizationId: this.$route.params.organizationId,
          });
        }

        this.helpers.editTimeSheetDialog.timeSheetData = {
          ...this.timeSheet,
          workedDuration: {
            start: {
              date: this.convertToDate(
                this.timeSheet.workedDuration.start.timestamp
              ),
              time: this.convertToTime(
                this.timeSheet.workedDuration.start.timestamp
              ),
            },
            end: {
              date: this.convertToDate(
                this.timeSheet.workedDuration.end.timestamp
              ),
              time: this.convertToTime(
                this.timeSheet.workedDuration.end.timestamp
              ),
            },
          },
          billableDuration: {
            start: {
              date: this.convertToDate(
                this.timeSheet.billableDuration.start.timestamp
              ),
              time: this.convertToTime(
                this.timeSheet.billableDuration.start.timestamp
              ),
            },
            end: {
              date: this.convertToDate(
                this.timeSheet.billableDuration.end.timestamp
              ),
              time: this.convertToTime(
                this.timeSheet.billableDuration.end.timestamp
              ),
            },
          },
        };
      } else {
        this.helpers.editTimeSheetDialog.timeSheetData = null;
      }
      this.helpers.editTimeSheetDialog.model =
        !this.helpers.editTimeSheetDialog.model;
      this.helpers.editTimeSheetDialog.errorMessage = "";
    },
    toggleArchiveTimeSheetDialog() {
      this.helpers.archiveTimeSheetDialog.model =
        !this.helpers.archiveTimeSheetDialog.model;
      this.helpers.archiveTimeSheetDialog.errorMessage = "";
    },
    toggleMoveBackToReviewDialog() {
      this.helpers.moveBackToReviewDialog.model =
        !this.helpers.moveBackToReviewDialog.model;
      this.helpers.moveBackToReviewDialog.errorMessage = "";
    },
    async toggleUpdatePayrollTypeDialog() {
      // If this is the open action, run getPayrollTypes()
      if (!this.helpers.updatePayrollTypeDialog.model) {
        await this.getPayrollTypes();
      }
      this.helpers.updatePayrollTypeDialog.newPayrollTypeId =
        this.timeSheet.payrollType.id;
      this.initializeCustomVariables();
      this.helpers.updatePayrollTypeDialog.model =
        !this.helpers.updatePayrollTypeDialog.model;
      this.helpers.updatePayrollTypeDialog.errorMessage = "";
    },
    toggleRecalculateSalaryDialog() {
      this.helpers.recalculateSalaryDialog.model =
        !this.helpers.recalculateSalaryDialog.model;
      this.helpers.recalculateSalaryDialog.errorMessage = "";
    },
    toggleApproveTimeSheetDialog() {
      this.helpers.approveTimeSheetDialog.model =
        !this.helpers.approveTimeSheetDialog.model;
      this.helpers.approveTimeSheetDialog.errorMessage = "";
      this.helpers.approveTimeSheetDialog.reasonValue = "";
    },
    toggleRejectTimeSheetDialog() {
      this.helpers.rejectTimeSheetDialog.model =
        !this.helpers.rejectTimeSheetDialog.model;
      this.helpers.rejectTimeSheetDialog.errorMessage = "";
      this.helpers.rejectTimeSheetDialog.reasonValue = "";
    },
    updateTimeSheet() {
      this.helpers.editTimeSheetDialog.isLoading = true;
      const callFunction = functions.httpsCallable(
        "accounting-updateTimeSheet"
      );
      const timeSheetData = {
        title: this.helpers.editTimeSheetDialog.timeSheetData.title || "",
        description: this.helpers.editTimeSheetDialog.timeSheetData.description,
        workedDuration: {
          start: {
            timestamp: this.convertToTimestamp(
              this.helpers.editTimeSheetDialog.timeSheetData.workedDuration
                .start.date,
              this.helpers.editTimeSheetDialog.timeSheetData.workedDuration
                .start.time
            ),
          },
          end: {
            timestamp: this.convertToTimestamp(
              this.helpers.editTimeSheetDialog.timeSheetData.workedDuration.end
                .date,
              this.helpers.editTimeSheetDialog.timeSheetData.workedDuration.end
                .time
            ),
          },
        },
        billableDuration: {
          start: {
            timestamp: this.convertToTimestamp(
              this.helpers.editTimeSheetDialog.timeSheetData.billableDuration
                .start.date,
              this.helpers.editTimeSheetDialog.timeSheetData.billableDuration
                .start.time
            ),
          },
          end: {
            timestamp: this.convertToTimestamp(
              this.helpers.editTimeSheetDialog.timeSheetData.billableDuration
                .end.date,
              this.helpers.editTimeSheetDialog.timeSheetData.billableDuration
                .end.time
            ),
          },
        },
        additionalData: {
          ...this.helpers.editTimeSheetDialog.timeSheetData.additionalData,
        },
      };
      console.log(timeSheetData);
      return callFunction({
        organizationId: this.$route.params.organizationId,
        timeSheetId: this.timeSheet.meta.id,
        timeSheetData: timeSheetData,
      })
        .then((result) => {
          if (result.data.success) {
            this.getTimeSheet();
            this.getLogs();
            this.helpers.editTimeSheetDialog.isLoading = false;
            this.toggleEditDialog();
            this.helpers.snackbar.message = "Stundenmeldung aktualisiert.";
            this.helpers.snackbar.isVisible = true;
          }
        })
        .catch((error) => {
          this.helpers.editTimeSheetDialog.isLoading = false;
          this.helpers.editTimeSheetDialog.errorMessage = error.message;
          console.error("Error updating time sheet salary: ", error);
        });
    },
    updatePayrollType() {
      this.helpers.updatePayrollTypeDialog.isLoading = true;
      const callFunction = functions.httpsCallable(
        "accounting-updateTimeSheetPayrollType"
      );
      console.log(this.helpers.updatePayrollTypeDialog.customVariables);
      return callFunction({
        organizationId: this.$route.params.organizationId,
        timeSheetId: this.timeSheet.meta.id,
        payrollTypeId: this.helpers.updatePayrollTypeDialog.newPayrollTypeId,
        payrollTypeInputVariables:
          this.helpers.updatePayrollTypeDialog.customVariables,
      })
        .then(() => {
          this.getTimeSheet();
          this.getLogs();
          this.helpers.updatePayrollTypeDialog.isLoading = false;
          this.toggleUpdatePayrollTypeDialog();
          this.helpers.snackbar.message =
            "Abrechnungsart für Stundenmeldung aktualisiert.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.updatePayrollTypeDialog.isLoading = false;
          this.helpers.updatePayrollTypeDialog.errorMessage = error.message;
          console.error("Error recalculating time sheet salary: ", error);
        });
    },
    recalculateSalary() {
      this.helpers.recalculateSalaryDialog.isLoading = true;
      const callFunction = functions.httpsCallable(
        "accountinng-recalculateTimeSheetSalary"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        timeSheetId: this.timeSheet.meta.id,
      })
        .then(() => {
          this.getTimeSheet();
          this.getLogs();
          this.helpers.recalculateSalaryDialog.isLoading = false;
          this.toggleRecalculateSalaryDialog();
          this.helpers.snackbar.message =
            "Aufwand für Stundenmeldung neu berechnet.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.recalculateSalaryDialog.isLoading = false;
          this.helpers.recalculateSalaryDialog.errorMessage = error.message;
          console.error("Error recalculating time sheet salary: ", error);
        });
    },
    approveTimeSheet() {
      this.helpers.approveTimeSheetDialog.isLoading = true;
      const callFunction = functions.httpsCallable(
        "accounting-approveTimeSheet"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        timeSheetId: this.timeSheet.meta.id,
        reason: this.helpers.approveTimeSheetDialog.reasonValue,
      })
        .then(() => {
          this.getTimeSheet();
          this.getLogs();
          this.helpers.approveTimeSheetDialog.isLoading = false;
          this.toggleApproveTimeSheetDialog();
          this.helpers.snackbar.message = "Stundenmeldung genehmigt.";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.approveTimeSheetDialog.isLoading = false;
          this.helpers.approveTimeSheetDialog.errorMessage = error.message;
          console.error("Error approving timeSheet: ", error);
        });
    },
    rejectTimeSheet() {
      this.$refs.rejectDialogForm.validate();
      if (this.helpers.rejectTimeSheetDialog.formIsValid) {
        this.helpers.rejectTimeSheetDialog.isLoading = true;
        const callFunction = functions.httpsCallable(
          "accounting-rejectTimeSheet"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,
          timeSheetId: this.timeSheet.meta.id,
          reason: this.helpers.rejectTimeSheetDialog.reasonValue,
        })
          .then(() => {
            this.getTimeSheet();
            this.getLogs();
            this.helpers.rejectTimeSheetDialog.isLoading = false;
            this.toggleRejectTimeSheetDialog();
            this.helpers.snackbar.message = "Stundenmeldung abgelehnt.";
            this.helpers.snackbar.isVisible = true;
          })
          .catch((error) => {
            this.helpers.rejectTimeSheetDialog.isLoading = false;
            this.helpers.rejectTimeSheetDialog.errorMessage = error.message;
            console.error("Error approving timeSheet: ", error);
          });
      } else {
        this.helpers.rejectTimeSheetDialog.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben ausgefüllt.";
      }
    },
    requestTimeSheetValidation() {
      this.helpers.requestValidationDialog.isLoading = true;
      const callFunction = functions.httpsCallable(
        "accounting-requestTimeSheetValidation"
      );
      return callFunction({
        organizationId: this.$route.params.organizationId,
        timeSheetId: this.timeSheet.meta.id,
      })
        .then(() => {
          this.getTimeSheet();
          this.getLogs();
          this.helpers.requestValidationDialog.isLoading = false;
          this.toggleRequestValidationDialog();
          this.helpers.snackbar.message =
            "Prüfung erfolgreich abgeschlossen   .";
          this.helpers.snackbar.isVisible = true;
        })
        .catch((error) => {
          this.helpers.requestValidationDialog.isLoading = false;
          this.helpers.requestValidationDialog.errorMessage = error.message;
          console.error("Error requesting validationn of timeSheet: ", error);
        });
    },
    archiveTimeSheet() {
      this.$refs.archiveDialogForm.validate();
      if (this.helpers.archiveTimeSheetDialog.formIsValid) {
        this.helpers.archiveTimeSheetDialog.isLoading = true;
        const callFunction = functions.httpsCallable(
          "accounting-archiveTimeSheet"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,
          timeSheetId: this.timeSheet.meta.id,
          reason: this.helpers.archiveTimeSheetDialog.reasonValue,
        })
          .then(() => {
            this.getTimeSheet();
            this.getLogs();
            this.helpers.archiveTimeSheetDialog.isLoading = false;
            this.toggleArchiveTimeSheetDialog();
            this.helpers.snackbar.message = "Stundenmeldung archiviert.";
            this.helpers.snackbar.isVisible = true;
          })
          .catch((error) => {
            this.helpers.archiveTimeSheetDialog.isLoading = false;
            this.helpers.archiveTimeSheetDialog.errorMessage = error.message;
            console.error("Error archiving timeSheet: ", error);
          });
      } else {
        this.helpers.rejectTimeSheetDialog.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben ausgefüllt.";
      }
    },
    moveBackToReview() {
      this.$refs.moveBackToReviewDialog.validate();
      if (this.helpers.moveBackToReviewDialog.formIsValid) {
        this.helpers.moveBackToReviewDialog.isLoading = true;
        const callFunction = functions.httpsCallable(
          "accounting-moveTimeSheetBackToReview"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,
          timeSheetId: this.timeSheet.meta.id,
          reason: this.helpers.moveBackToReviewDialog.reasonValue,
        })
          .then(() => {
            this.getTimeSheet();
            this.getLogs();
            this.helpers.moveBackToReviewDialog.isLoading = false;
            this.toggleMoveBackToReviewDialog();
            this.helpers.snackbar.message =
              "Stundenmeldung erneut zur Prüfung übergeben.";
            this.helpers.snackbar.isVisible = true;
          })
          .catch((error) => {
            this.helpers.moveBackToReviewDialog.isLoading = false;
            this.helpers.moveBackToReviewDialog.errorMessage = error.message;
            console.error("Error moving time sheet back to review: ", error);
          });
      } else {
        this.helpers.moveBackToReviewDialog.errorMessage =
          "Fehler: Es sind nicht alle Pflichtangaben ausgefüllt.";
      }
    },
  },
};
</script>
